<template>
  <div class="rec-block-reco">
    <div class="content">
      <div class="title">
        {{
          data.text.length < 2
            ? $t("rec-recommendation-title-single")
            : $t("rec-recommendation-title-plural")
        }}
      </div>
      <span v-if="data.text.length == 1" v-html="$t(data.text[0])"></span>
      <ul v-else>
        <li v-for="item in data.text" :key="item">{{ $t(item) }}</li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: "RecBlockReco",
  props: ["data", "content"],
};
</script>

<style lang="stylus">
.rec-block-reco
  background-color: #479095
  background-image: url('~@/assets/reco-item-bg.svg')
  background-position: 90px -400px
  background-repeat: no-repeat
  background-size: 900px
  min-height: 450px
  text-align: left
  display: flex
  flex-direction: column
  justify-content: center
  .content
    width: 50%
    padding-top 80px
    padding-bottom 80px

    color: #fff
    font-weight bold
    r-font-size(24, 30)
    ul
      li
        margin-bottom: 1.2em
    @media only screen and (max-width: 768px)
      width: 70%
    .title
      r-font-size(48, 55)
      margin-bottom: 25px
</style>
