<template>
  <div class="view recommendations">
    <div v-if="!content || content.length == 0" class="page-loader">
      <Loader></Loader>
    </div>
    <div class="intro-rec">
      <div class="content">
        <img
          src="~@/assets/rec-logo.svg"
          alt="The future with Wakimbizi - Raising the voices for urban refugees"
        />
        <img class="smaller" src="~@/assets/rec-image.svg" />
      </div>
    </div>
    <div class="content-rec" v-if="content">
      <div class="content">
        <div class="intro-title" v-html="$t('rec_title')"></div>
        <div class="intro-text" v-html="$t('rec_intro')"></div>

        <img class="separator" src="~@/assets/separator-green-double.svg" />
        <div class="intro-title" v-html="$t('rec_title_2')"></div>

        <div class="big-text" v-html="$t('rec_par_2')"></div>
        <img class="img-dots" src="~@/assets/dots-recom.svg" />

        <FlourishEmbed
          v-if="item.chartUrl"
          v-for="item in chartsList"
          :text="$t(item.title)"
          :chartUrl="$t(item.chartUrl)"
          :key="item.chartUrl"
          :frameHeight="$t(item.chartUrl + '_height')"
        ></FlourishEmbed>
      </div>
      <RecNavigationModule
        :content="content"
        mode="general"
      ></RecNavigationModule>
    </div>

    <ContactUs :content="generalContent"></ContactUs>
  </div>
</template>

<script>
import { getGoogleSheetData } from "@/api";
import ContactUs from "./sections/ContactUs.vue";
import Loader from "../components/Loader.vue";
import FlourishEmbed from "../components/FlourishEmbed.vue";
import RecNavigationModule from "../components/RecNavigationModule.vue";

export default {
  name: "Recommendations",
  components: { ContactUs, Loader, FlourishEmbed, RecNavigationModule },
  props: ["generalContent"],
  data() {
    return {
      content: [],
      topics: ["IDs", "inclusive", "education", "vital"],
      currentTopic: "IDs",
      chartsList: [
        {
          title: "rec-bit-about-us_text_1",
          chartUrl: "rec-bit-about-us_viz_1",
        },
        {
          title: "rec-bit-about-us_text_2",
          chartUrl: "rec-bit-about-us_viz_2",
        },
        {
          title: "rec-bit-about-us_text_3",
          chartUrl: "rec-bit-about-us_viz_3",
        },
        {
          title: "rec-bit-about-us_text_4",
          chartUrl: "rec-bit-about-us_viz_4",
        },
      ],
    };
  },
  mounted() {
    getGoogleSheetData(["recommendations"]).then((resp) => {
      this.content = resp;
    });
  },
  methods: {
    toggleTopic(topic) {
      this.currentTopic = this.currentTopic !== topic ? topic : "";
    },
  },
};
</script>

<style lang="stylus">
.recommendations
  .intro-rec
    background-color: #EBAD4B
    background-image: url('~@/assets/rec-bg.svg')
    background-position: center center
    background-repeat: no-repeat
    background-size: contain
    @media only screen and (max-width: 768px)
      min-height: auto
      background-image none
      padding 20px 0px
    .content
      text-align: center
      color: #fff
      width 50%
      @media only screen and (max-width: 768px)
        width 80%
    img
      width: 100%
      &.smaller
        margin-top: 20px
        margin-bottom: -180px
        @media only screen and (max-width: 768px)
          margin-bottom: 0px
  .content-rec
    background-color: #c3dcdd
    color: #2F1F45
    background-image: url('~@/assets/rec-bg-pattern.svg')
    background-position: 50px 50px
    background-size: contain
    background-repeat: repeat-y
    padding-top: 160px
    @media only screen and (max-width: 768px)
      padding-top: 10px

    .content
      width: 50%
      @media only screen and (max-width: 768px)
        width 90%

    .intro-title
      r-font-size(60,72)
      font-weight: 700;
      margin-bottom: 30px
      text-align: left

      @media only screen and (max-width: 768px)
        font-size: 30px
        line-height: 40px
        max-width 100%
    .intro-text
      r-font-size(24,40)
      font-weight: 400;
      text-align: left
      @media only screen and (max-width: 768px)
        font-size: 20px
        max-width 100%
        line-height: 30px
    .big-text
      font-weight: bold
      r-font-size(24,40)
    img
      width 100%
      margin: 30px 0px
    .separator
      width 100%
      display: block
      margin: 50px 0px

    .img-dots
      margin-bottom: 50px
</style>
