<template>
  <section class="big-issue" id="big-issue">
    <img class="section-title" src="~@/assets/big-issue-header.svg" />
    <div class="content">
      <div class="block bold" v-html="$t('bigissue_par1')"></div>
      <div class="block" v-html="$t('bigissue_par2')"></div>
      <div class="block" v-html="$t('bigissue_par3')"></div>
      <div class="block">
        <div
          class="button bigissue-list-button"
          @click="$router.push('/documents')"
        >
          See the document overview here
        </div>
      </div>
      <div class="quote-block">
        <img class="quote-person" src="~@/assets/big-issue-quote-person.svg" />
        <div class="quote-text">
          {{ $t("bigissue_quote") }}
          <div class="quote-author">{{ $t("bigissue_quote_author") }}</div>
        </div>
      </div>
      <!--
      <img src="~@/assets/separator-blue.svg">
      <div class="block bigissue-list">
        <div class="bigissue-list-content">
          <span v-html="$t('bigissue_list_text')"></span>
          <ol>
            <li v-for="item in bigIssueList" :key={item}>{{item}}</li>
          </ol>
        </div>
        <div class="bigissue-list-button">{{$t('bigissue_list_button')}}</div>
      </div>
      -->
    </div>
  </section>
</template>

<script>
export default {
  name: "BigIssue",
  props: ["content"],
  computed: {
    bigIssueList() {
      return this.$t("bigissue_list")
        ? this.$t("bigissue_list").split(",")
        : [];
    },
  },
};
</script>

<style lang="stylus" scoped>
#big-issue
  background: #479095;
  color #fff
  position relative
  padding-bottom 10px
  .section-title
    width: 80%
    margin-top: 30px
    margin-bottom: 30px
    @media only screen and (max-width: 768px)
      margin-bottom 0px
  .content
    @media only screen and (max-width: 768px)
      padding 0px
  .quote-block
    display: flex
    margin 50px 0px
    @media only screen and (max-width: 768px)
      flex-direction: column
      align-items: center
    .quote-person
      width: 45%
      @media only screen and (max-width: 768px)
        width: 60%
        margin-bottom: 30px
    .quote-text
      margin-left 5%
      r-font-size(28,42)
      font-weight: 700
      text-align: left
      .quote-author
        margin-top 1em
        font-weight: 300


  .bigissue-list
    font-weight 700
    display flex
    align-items flex-end
    .bigissue-list-content
      width 50%
  .bigissue-list-button
    width: 400px
    margin: auto;
    margin-bottom: 90px
    @media only screen and (max-width: 768px)
      width: 90%
</style>
