<template>
  <div class="view download">
    <div v-if="!content || content.length == 0" class="page-loader">
      <Loader></Loader>
    </div>
    <div class="content">
      <div class="download-title">{{$t('download_title')}}</div>
      <div class="download-intro">
        <div class="download-text" v-html="$t('download_text')"></div>
        <div class="download-link-full"><a :href="$byKey('full_data')['Link']">{{$t('full_data')}}</a></div>
      </div>
      <div class="download-gallery">
        <div class="download-item" v-for="topic in topics" :key="topics">
          <img class="topic-image" :src="'/images/illustrations/illustration_'+topic+'.svg'">
          <a :href="$byKey(topic)['Link']">{{$t(topic)}}</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {getGoogleSheetData} from '@/api'
import Loader from '../components/Loader.vue'

export default {
    name: "Download",
    data() {
        return {
            topics: ["IDs", "access", "movement", "education", "privacy", "exclusion", "inclusive"],
            content: [],
        };
    },
    mounted() {
        getGoogleSheetData(["download"]).then(resp => {
            this.content = resp;
        });
    },
    components: { Loader }
}
</script>

<style lang="stylus">
.download
  background: #479095
  color #fff
  text-align: left
  a
    color #fff
  .download-title
    font-weight: 700
    r-font-size(70,105)
    margin: 40px 0px
  .download-intro
    font-weight: 700
    r-font-size(20,30)
    display: flex
    margin-bottom: 80px
    @media only screen and (max-width: 768px)
      flex-direction: column
    .download-text
      width: 65%
      margin-right: 10%
      margin-bottom: 20px
  .download-gallery
    display: grid;
    grid-template-columns 1fr 1fr
    grid-column-gap 60px
    grid-row-gap 60px
    @media only screen and (max-width: 768px)
      grid-template-columns 1fr
    .download-item
      border-top: 1px solid #fff
      padding 20px
      text-align: center
      font-weight: 700
      r-font-size(30,45)
    .topic-image
      width: 80%
      margin: 20px auto
      display block
</style>