<template>
  <div class="rec-block-quotes">
    <div class="quotes-container">
      <div
        class="quote"
        v-for="quote in data.quotes"
        :key="quote.text"
        v-if="data.quotes.length > 1"
      >
        <img
          class="quote-image"
          :src="'/images/illustrations/' + $t(quote.pic) + '.svg'"
        />
        <div class="quote-text">"{{ $t(quote.text) }}"</div>
        <div class="quote-author">{{ $t(quote.author) }}</div>
      </div>
      <div class="quote single-quote" v-else>
        <div class="quote-content">
          <div
            class="quote-text"
            v-bind:class="$t(data.quotes[0].text).length < 300 && 'short-text'"
          >
            "{{ $t(data.quotes[0].text) }}"
          </div>
          <div class="quote-author">{{ $t(data.quotes[0].author) }}</div>
        </div>
        <img
          class="quote-image"
          :src="'/images/illustrations/' + $t(data.quotes[0].pic) + '.svg'"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "RecBlockQuotes",
  props: ["data", "content"],
};
</script>

<style lang="stylus">
.rec-block-quotes
  width: 100%
  background: #2f1f45
  color #fff

  .quotes-container
    width: 85%
    margin: auto
    display: flex
    justify-content: space-around
    flex-wrap: wrap
    @media only screen and (max-width: 768px)
      flex-direction: column
      align-items: center
      padding: 20px

  .quote
    background-image: url('~@/assets/topic-quote-bg.svg')
    background-size: contain
    background-repeat: no-repeat
    flex: 0 0 auto
    width: 30%
    margin: 50px 10px;
    text-align: left
    @media only screen and (max-width: 768px)
      width: 50%
      background-size: contain
      margin: 10px;

    .quote-image
      width: 60%
      margin 20px auto
      display: block
    .quote-text
      width: 90%
      margin: auto
      r-font-size(18,27)
      font-weight: 700
      &.short-text
        r-font-size(35,45)
    .quote-author
      r-font-size(19,19)
      font-weight: 300
      width: 90%
      margin: auto
      margin-top 1em
      margin-bottom: 2em

    &.single-quote
      width: 80%
      display flex
      background-size: 200px
      .quote-content
        width: 50%
      .quote-image
        width: 30%
        margin 20px auto
        display: block
</style>
