import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import VueSocialSharing from "vue-social-sharing";
import VueGtag from "vue-gtag";

Vue.use(
  VueGtag,
  {
    config: { id: "G-2DSH9LZ4E7" },
  },
  router
);

Vue.use(VueSocialSharing);
Vue.config.productionTip = false;

Vue.mixin({
  methods: {
    $t: function(key) {
      let r = this.content && this.content.find((el) => el.Key == key);
      return r ? r["EN"] : "";
    },
    $list: function(key) {
      let r = this.content && this.content.filter((el) => el.Key == key);
      return r ? r.map((e) => e["EN"]) : [];
    },
    $currentKey: function(key) {
      return key + "_" + "EN";
    },
    $byKey: function(key) {
      let r = this.content && this.content.find((el) => el.Key == key);
      return r;
    },
  },
});

new Vue({
  router,
  render: (h) => h(App),
}).$mount("#app");
