<template>
  <section class="top-rec" id="top-rec">
    <div class="content">
      <div class="rec-title">
        {{$t('rec_title')}}
      </div>
      <div class="rec-container">
        <div class="rec-group rec-kenya">
          <img src="~@/assets/kenya-rec.svg"  class="title-img">
          <div class="rec-item" v-for="rec, i in recKenya" :key="'kenya-'+i">
            <!--<div v-bind:class="['rec-item-dot', `dot-${i+1}`]"></div>-->
            - {{rec}}
          </div>
        </div>
        <div class="rec-group rec-uganda">
          <img src="~@/assets/uganda-rec.svg" class="title-img">
          <div class="rec-item" v-for="rec, i in recUganda" :key="'uganda-'+i">
            <!--<div v-bind:class="['rec-item-dot', `dot-${(i + 2)%4 + 1 }`]"></div>-->
            - {{rec}}
          </div>
        </div>
      </div>
      <router-link class="rec-call-to-action" to="/recommendations">
        {{$t('rec_see_all')}}
      </router-link>
    </div>

  </section>
</template>

<script>
export default {
  name: 'TopRec',
  props: ['content'],
  computed: {
    recKenya () {
      return this.$list('rec_kenya')
    },
    recUganda () {
      return this.$list('rec_uganda')
    },
  }
}
</script>

<style lang="stylus" scoped>
#top-rec
  background: #C3DCDD;
  color: #2F1F45
  text-align: center
  .rec-title
    font-weight 700
    r-font-size(50, 68)
    margin-bottom 40px
    font-family: 'Bungee'
    @media only screen and (max-width: 768px)
      font-size 25px
      line-height: 30px
  .rec-container
    display flex
    @media only screen and (max-width: 768px)
      flex-direction: column

    .rec-group
      width 50%
      @media only screen and (max-width: 768px)
        width 100%
        text-align left
        &:not(:last-child)
          border none !important
          padding: 0px
      &:not(:last-child)
        border-right 1px solid
        padding-right: 30px
      
      &:last-child
        margin-left: 30px
        @media only screen and (max-width: 768px)
          margin-left 0px
      .title-img
        height: 15vw
        margin-bottom: 30px
        @media only screen and (max-width: 768px)
          height: 90px
          display: block
          margin: auto
          margin-bottom: 30px
      .title-uganda
        margin-top: 16px
        margin-bottom: 30px
      .title-kenya
        margin-bottom: 14px
      .rec-item
        text-align: left
        margin-bottom: 30px
        font-weight: 400
        r-font-size(20,31)
        display: flex
        .rec-item-dot
          min-height: 50px
          min-width: 81px
          margin-right: 30px
          flex: 0 0 81px
          background-position: top left
          background-repeat: no-repeat
          background-size: contain
          margin-top 5px
          &.dot-1
            background-image: url('~@/assets/list-dot-1.svg')
          &.dot-2
            background-image: url('~@/assets/list-dot-2.svg')
          &.dot-3
            background-image: url('~@/assets/list-dot-3.svg')
          &.dot-4
            background-image: url('~@/assets/list-dot-4.svg')
  .rec-call-to-action
    display block
    margin: 60px auto
    color #2F1F45
    font-weight: 700
    r-font-size(25,30)
    border 3px solid #2F1F45;
    border-radius: 50px
    cursor pointer
    padding 8px 10px
    width: 70%
    text-decoration none
</style>  