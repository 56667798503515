<template>
  <div class="popup-document documents">
    <div class="popup-header">
      <div class="title">Document overview kenya & uganda</div>
      <div class="close" @click="$router.push('/story')"></div>
    </div>
    <div class="popup-content">
      <div class="country-document">
        <div class="country-document-header">
          <img src="~@/assets/kenya-green.svg" class="title-kenya" />
          <div class="intro-header">
            Here is an overview of some of the documents here in Kenya.
          </div>
        </div>
        <div
          class="document-item"
          v-for="doc in documentsByCountry('Kenya')"
          :key="doc[$currentKey('Name')]"
        >
          <div class="document-title-block">
            <div class="document-title" v-html="doc[$currentKey('Name')]"></div>
            <div class="document-issued" v-if="doc[$currentKey('Issuer')]">
              <span class="legend">Issued by </span>
              <span v-html="doc[$currentKey('Issuer')]"></span>
            </div>
          </div>
          <div class="document-usage-block">
            <div class="document-use" v-if="doc[$currentKey('Document_use')]">
              <span class="legend">Document use: </span
              ><span v-html="doc[$currentKey('Document_use')]"></span>
            </div>
            <div
              class="document-use"
              v-if="doc[$currentKey('What_you_cannot_do_without_it')]"
            >
              <span class="legend">What you cannot do without it: </span
              ><span
                v-html="doc[$currentKey('What_you_cannot_do_without_it')]"
              ></span>
            </div>
          </div>
        </div>
      </div>
      <div class="country-document">
        <div class="country-document-header">
          <img src="~@/assets/uganda-green.svg" class="title-uganda" />
          <div class="intro-header">
            Here is an overview of some of the documents here in Uganda.
          </div>
        </div>
        <div
          class="document-item"
          v-for="doc in documentsByCountry('Uganda')"
          :key="doc['Name']"
        >
          <div class="document-title-block">
            <div class="document-title">{{ doc[$currentKey("Name")] }}</div>
            <div class="document-issued">
              <span class="legend">Issued by</span>
              {{ doc[$currentKey("Issuer")] }}
            </div>
          </div>
          <div class="document-usage-block">
            <div class="document-use">
              <span class="legend">Document use:</span>
              {{ doc[$currentKey("Document_use")] }}
            </div>
            <div class="document-use">
              <span class="legend">What you cannot do without it:</span>
              {{ doc[$currentKey("What_you_cannot_do_without_it")] }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getGoogleSheetData } from "@/api";

export default {
  name: "Documents",
  data() {
    return {
      content: [],
    };
  },
  mounted() {
    getGoogleSheetData(["documents"]).then((resp) => {
      this.content = resp;
    });
  },
  methods: {
    documentsByCountry(country) {
      return this.content.filter((el) => el["Country"] == country);
    },
  },
};
</script>

<style lang="stylus">
.popup-document
  position: fixed
  top 0
  left: 0
  width: 100%
  height: 100vh
  background: #2F1F45
  color: #fff
  a,a:visited
    color #fff
  .popup-header
    height: 80px
    background: #479095
    color #fff
    display: flex
    align-items: center;
    justify-content: center;
    @media only screen and (max-width: 768px)
      padding: 0px 40px
    .title
      font-family: 'Bungee'
      r-font-size(34,40);
      position relative
      text-align: center

    .close
      position: absolute
      right: 40px
      cursor pointer
      display block
      width: 30px
      height: 30px
      background-image url('~@/assets/icon-close.svg')
      background-size: cover
      @media only screen and (max-width: 768px)
        width: 20px
        height: 20px
        right: 20px
  .popup-content
    background: #2F1F45
    overflow: scroll
    height: calc(100vh - 80px)

    .country-document-header
      display: flex
      width 70%
      max-width 1100px
      margin 0 auto
      padding 40px 0px
      @media only screen and (max-width: 768px)
        width 90%
        margin 0 auto
        padding 20px 0px

      img
        width: 45%
        margin-right: 5%
      .intro-header
        r-font-size(30,45)
        font-weight: 600
        text-align: left


    .document-item
      border-top 1px solid #fff
      padding 20px 0px
      display: flex
      max-width 1100px
      margin 0 auto
      padding 40px 0px
      margin-bottom: 50px
      text-align: left
      @media only screen and (max-width: 768px)
        width 90%
        margin 0 auto
        padding 20px 0px
        flex-direction: column
      .document-title-block
        flex 0 0 30%
        margin-right: 80px
        @media only screen and (max-width: 768px)
          flex 0 0 100%
          margin-bottom: 20px
        .document-title
          r-font-size(28,42);
          font-weight: 600;
        .document-issued
          r-font-size(16,24);
          font-weight: 400;
      .document-usage-block
        flex 1 1 auto
        .document-use
          r-font-size(18,27);
          font-weight: 600;
      .legend
        color #ECAE40
</style>
