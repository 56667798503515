export const API_KEY = 'AIzaSyBJlXPVpqBRbfJX38M9UUc3auZB4zQkRmU';
export const SHEET_ID = '1i1B2bFWuoMD12uqmp7Jgepy9vx5TnVxGeyjHI3OawXg'
export const SHEET_NAME = 'EN' // name of the data range in a spreadsheet

let cache = []

function constructSheetApiUrl(sheetId, ranges, apiKey) {
  const BASE_URL = 'https://sheets.googleapis.com/v4/spreadsheets';
  let rangesParams = ''
  rangesParams = rangesParams.concat(...ranges.map(r => `ranges=${r}&`))
  return `${BASE_URL}/${sheetId}/values:batchGet?${rangesParams}key=${apiKey}`;
}

function parseResponseJSON(respJson) {
  if (respJson && respJson.valueRanges && respJson.valueRanges.length) {
    const { values } = respJson.valueRanges[0];
    if (values.length) {
      //  first row is an array of keys
      const keys = values.shift();
      
      return values.reduce((acc, row) => {
        const obj = row.reduce((acc, value, i) => {
          acc[keys[i]] = value;
          return acc;
        }, {});

        acc.push(obj);
        return acc;
      }, []);
    }

    return {};
  }
}

export async function getGoogleSheetData(ranges) {
  const url = constructSheetApiUrl(SHEET_ID, ranges, API_KEY);
  let data = {}
  if(!cache[url]) {
    const response = await fetch(url);
    const json = await response.json();
    data = parseResponseJSON(json);
    cache[url] = data
  } else{
    data = cache[url]
  }
  return data;
}
