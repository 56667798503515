<template>
  <section id="barriers" class="barriers">
    <!--<img class="section-title" src="~@/assets/barriers-title.svg">
    <div class="block" v-html="$t('barriers_intro')"></div>
    -->
    <div class="topics">
      <div class="topic" v-for="(topic, tIndex) in topics" :key="topic">
        <div class="content">
          <div
            class="topic-header"
            @click="toggleTopic(topic)"
            :ref="'topic-' + topic"
          >
            <div class="topic-header-title">{{ $t(`${topic}_title`) }}</div>
            <div
              v-bind:class="[{ open: currentTopic == topic }, 'toggle-icon']"
            ></div>
          </div>
        </div>
        <div class="topic-content" v-if="currentTopic == topic">
          <div class="content">
            <img
              class="topic-image"
              :src="'/images/illustrations/illustration_' + topic + '.svg'"
            />
            <div class="topic-block" v-html="$t(`${topic}_intro`)"></div>
            <FlourishViz
              :vizId="'viz_' + topic"
              :steps="$list('viz_' + topic)"
            ></FlourishViz>
          </div>
          <div class="quotes">
            <div class="quotes-container">
              <div
                class="quote"
                v-for="quote in getQuotesByTopic(topic)"
                :key="quote.text"
              >
                <img
                  class="quote-image"
                  :src="'/images/illustrations/' + quote.pic + '.svg'"
                />
                <div class="quote-text">"{{ quote.text }}"</div>
                <div class="quote-author">{{ quote.author }}</div>
              </div>
            </div>
          </div>
          <div class="continue" v-if="tIndex < topics.length - 1">
            Continue and check out the other challenges we face
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import CirclePlot from "../../components/CirclePlot";
import FlourishViz from "../../components/FlourishViz.vue";

export default {
  name: "Barriers",
  props: ["content"],
  components: { CirclePlot, FlourishViz },
  data() {
    return {
      topics: [
        "IDs",
        "access",
        "movement",
        "education",
        "privacy",
        "exclusion",
        "inclusive",
      ],
      currentTopic: "IDs",
    };
  },
  computed: {
    baseUrl() {
      return window.location.origin;
    },
  },
  methods: {
    toggleTopic(topic) {
      this.currentTopic = this.currentTopic !== topic ? topic : "";

      if (this.currentTopic) {
        var element = this.$refs["topic-" + this.currentTopic];
        var top = element[0].offsetTop - 10;
        window.scrollTo(0, top);
      }
    },
    getQuotesByTopic(topic) {
      let qs = this.$list(topic + "_quote");
      let auths = this.$list(topic + "_quote_author");
      let pics = this.$list(topic + "_quote_pic");

      return qs.map((q, i) => {
        return {
          text: q,
          author: auths[i] || "",
          pic: pics[i] || "#",
        };
      });
    },
  },
};
</script>

<style lang="stylus" scoped>
.barriers
  background: #C3DCDD
  color #2F1F45
  text-align left
  .section-title
    width: 60%
    margin-bottom: 20px
  .topics
    padding-top 70px
    padding-bottom 30px
    .topic
      .content
        padding: 30px 0px
      .topic-header
        background: #2F1F45;
        color #fff
        height: 120px
        border-radius: @height 0 0 0
        display: flex
        align-items: center
        cursor pointer
        .topic-header-title
          margin-left: 100px
          text-align: left
          font-weight: 600
          r-font-size(35,40)
          @media only screen and (max-width: 768px)
            margin-left 60px
        .toggle-icon
          margin-left: auto
          width: 40px
          min-width: 40px
          height: 40px
          margin-right: 40px
          position: relative;
          &:before,
          &:after
            content: "";
            position: absolute;
            background-color: white;
            transition: transform 0.25s ease-out;

          /* Vertical line */
          &:before
              top: 0;
              left: 50%;
              width: 8px;
              height: 100%;
              margin-left: -4px;

          /* horizontal line */
          &:after
              top: 50%;
              left: 0;
              width: 100%;
              height: 8px;
              margin-top: -4px;

          &.open
              &:before
                transform: rotate(90deg)
              &:after
                transform: rotate(180deg)
    .topic-content
      .topic-image
        width: 100%
        margin: auto
      .topic-block
        r-font-size(30,45)
        font-weight: 400
        margin: 30px 0px
      .chart-block-container
        text-align: center
        display: flex
        flex-wrap: wrap
        .chart-block
          text-align: center
          margin: 30px 0px
          .group-par
            width: 80%
            margin: auto
            r-font-size(30,45)
          .group-chart
            border-bottom: 1px solid
            margin: auto
    .quotes
      background: #2F1F45
      color #fff
      width: 100%
      .quotes-container
        width: 85%
        margin: auto
        display: flex
        justify-content: space-between
        flex-wrap: wrap
        @media only screen and (max-width: 768px)
          flex-direction: column
          align-items: center
          padding: 20px

      .quote
        background-image: url('~@/assets/topic-quote-bg.svg')
        background-size: contain
        background-repeat: no-repeat
        flex: 0 0 auto
        width: 30%
        margin: 50px 10px;
        @media only screen and (max-width: 768px)
          width: 50%
          background-size: contain
          margin: 10px;

        .quote-image
          width: 60%
          margin 20px auto
          display: block
        .quote-text
          width: 90%
          margin: auto
          r-font-size(18,27)
          font-weight: 700
        .quote-author
          font-family: 'Lora'
          r-font-size(19,19)
          font-weight: 700
          width: 90%
          margin: auto
          margin-top 1em
          margin-bottom: 2em
  .continue
    background: #ECAE40
    color: #000000
    width 100%
    text-align: center
    r-font-size(30,45)
    font-weight: 400
    padding 8px
</style>
