<template>
  <div class="view landing">
    <div class="content">
      <h1>The Future with WAKIMBIZI</h1>
      <img src="~@/assets/landing-title.svg">
    </div>
    <img class="illustration" src="~@/assets/landing-illustration.svg">
    <div class="content">
      <div class="intro-text">
        <div class="block">{{$t('landing_par_1')}}</div>
        <div class="block bold">{{$t('landing_par_2')}}</div>
      </div>
      <div class="call-to-action">
        <div class="cta-text">{{$t('landing_envision')}}</div>
        <router-link class="cta-button" to="/story">{{$t('landing_button')}}</router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'LandingPage',
  props: ['generalContent'],
  mounted (){
  },
  computed: {
    content () {
      return this.generalContent
    }
  }
}
</script>

<style lang="stylus">
.landing
  background-color: #479095
  background-image: url('~@/assets/landing-bg.svg')
  background-repeat: no-repeat
  background-size: cover
  text-align: center
  .illustration
    width: 90%
    max-width 1200px
  .content
    h1 
      display: none
    img
      width 100%
      margin-bottom: 30px
    .block
      font-weight: 400
      r-font-size(24,37)
      margin-bottom: 50px
      color: #fff
      text-align: left
      &.bold
        font-weight: 700
    .call-to-action
      text-align: center
      background-image: url('~@/assets/yellow-gradient.svg')
      background-repeat: no-repeat
      background-size: 40%
      background-position: left top
      color: $white
      min-height: 350px
      display: flex
      flex-direction: column
      align-items: center
      justify-content: flex-end
      margin-bottom: 90px
      .cta-text
        font-weight: 700
        r-font-size(60,80)
        width 70%
        margin: 50px auto

      .cta-button
        background: #2F1F45
        align-content: center
        justify-content: center
        padding: 30px 60px
        cursor pointer
        color #fff
        text-decoration: none
        border-radius: 10px
        font-weight: 700
        r-font-size(25,37)
        margin-bottom: -50px


</style>