<template>
  <div class="viz-container">
    <div class="story-label">{{steps[currentSlide]}}</div>
    <div class="viz">
      <div v-bind:class="['nav', 'left', currentSlide == 0 && 'inactive']" @click="()=>currentSlide > 0 && currentSlide--"></div>
      <iframe ref="iframe" scrolling="0" frameborder="0" class="flourish-embed" :src="'./flourish/'+vizId+'/index.html'"></iframe>
      <div v-bind:class="['nav', 'right', currentSlide == steps.length - 1 && 'inactive']" @click="()=> currentSlide < steps.length - 1 && currentSlide++"></div>
    </div>
    <div class="stepper">
      <div v-bind:class="['step', (i === currentSlide) && 'active']" v-for="(step, i) in steps" :key="'dot-'+i" @click="() => currentSlide = i"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FlourishViz',
  props: ['steps', 'vizId'],
  data () {
    return {
      currentSlide: 0
    }
  },
  mounted () {
  },
  watch: {
    currentSlide () {
      var iframe = this.$refs['iframe']
      iframe.contentWindow.window.location.hash = 'slide-' + this.currentSlide
    }
  }

}
</script>

<style lang="stylus" scoped>
.viz-container
  text-align: center
  width 100%
  margin-top 50px
  .story-label
    r-font-size(30,45)
    font-weight: 700
  .viz
    display flex
    justify-content: space-between
    align-items: center
    .nav
      background-image: url('~@/assets/arrow-left.svg')
      width 50px
      height 50px
      background-size contain
      background-repeat no-repeat
      &.right
        transform rotate(180deg)
      &.inactive
        opacity 0.3
        
  .flourish-embed
    width: 70vw
    height: 65vh
    display: block
  .stepper
    display: flex
    align-items: center
    width 100%
    justify-content: center
    .step
      height: 15px
      width  @height
      opacity 0.3
      margin: 5px
      transform: rotate(45deg)
      background: #2F1F45
      &.active
        opacity 1
</style>