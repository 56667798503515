<template>
  <div class="view recommendations-list">
    <div v-if="!content || content.length == 0" class="page-loader">
      <Loader></Loader>
    </div>
    <div class="intro-rec">
      <div class="content">
        <img
          v-if="currentCountry == 'kenya'"
          class="location-title"
          src="~@/assets/kenya-purple-2.svg"
        />
        <img
          v-if="currentCountry == 'uganda'"
          class="location-title"
          src="~@/assets/uganda-purple-2.svg"
        />
        <div class="reco-title">{{ $t("rec-section-title") }}</div>
      </div>
    </div>
    <div class="content-rec" v-if="content">
      <RecTopicItem
        v-for="(topic, tIndex) in topics"
        :key="topic.id"
        :topic="topic"
        :content="content"
        :startOpen="true"
      ></RecTopicItem>
      <RecNavigationModule
        :content="content"
        :mode="currentCountry"
      ></RecNavigationModule>
    </div>
    <ContactUs :content="generalContent"></ContactUs>
  </div>
</template>

<script>
import { getGoogleSheetData } from "@/api";
import ContactUs from "./sections/ContactUs.vue";
import Loader from "../components/Loader.vue";

import RecNavigationModule from "../components/RecNavigationModule.vue";
import RecTopicItem from "../components/RecTopicItem.vue";

import { text } from "d3";

export default {
  name: "Recommendations",
  components: {
    ContactUs,
    Loader,
    RecNavigationModule,
    RecTopicItem,
  },
  props: ["generalContent"],
  data() {
    return {
      content: [],
      dataStructure: {
        kenya: {
          topics: [
            {
              id: "id",
              blocks: [
                {
                  type: "text_vizs",
                  data: {
                    mainText: "rec-kenya-id-intro-text_1",
                    embeds: [
                      {
                        title: "rec-kenya-id-text_1",
                        chartUrl: "rec-kenya-id-viz_1",
                      },
                      {
                        title: "rec-kenya-id-text_2",
                        chartUrl: "rec-kenya-id-viz_2",
                      },
                      {
                        title: "rec-kenya-id-text_3",
                        chartUrl: "rec-kenya-id-viz_3",
                      },
                      {
                        title: "rec-kenya-id-text_4",
                        chartUrl: "rec-kenya-id-viz_4",
                      },
                      {
                        title: "rec-kenya-id-text_5",
                        chartUrl: "rec-kenya-id-viz_5",
                      },
                      {
                        title: "rec-kenya-id-text_6",
                        chartUrl: "rec-kenya-id-viz_6",
                      },
                    ],
                  },
                },
                {
                  type: "quotes",
                  data: {
                    quotes: [
                      {
                        pic: "rec-kenya-id-quote-1-pic",
                        text: "rec-kenya-id-quote-1-text",
                        author: "rec-kenya-id-quote-1-author",
                      },
                      {
                        pic: "rec-kenya-id-quote-2-pic",
                        text: "rec-kenya-id-quote-2-text",
                        author: "rec-kenya-id-quote-2-author",
                      },
                    ],
                  },
                },
                {
                  type: "recommendationItem",
                  data: {
                    text: ["rec-kenya-id-reco-1"],
                  },
                },
                {
                  type: "text_vizs",
                  data: {
                    mainText: "rec-kenya-id-intro-text_2",
                    embeds: [
                      {
                        title: "rec-kenya-id-text_7",
                        chartUrl: "rec-kenya-id-viz_7",
                      },
                    ],
                  },
                },
                {
                  type: "recommendationItem",
                  data: {
                    text: ["rec-kenya-id-reco-2-1", "rec-kenya-id-reco-2-2"],
                  },
                },
                {
                  type: "text_vizs",
                  data: {
                    mainText: "rec-kenya-id-intro-text_3",
                    embeds: [
                      {
                        title: "rec-kenya-id-text_8",
                        chartUrl: "rec-kenya-id-viz_8",
                      },
                    ],
                  },
                },
                {
                  type: "quotes",
                  data: {
                    quotes: [
                      {
                        pic: "rec-kenya-id-quote-3-pic",
                        text: "rec-kenya-id-quote-3-text",
                        author: "rec-kenya-id-quote-3-author",
                      },
                    ],
                  },
                },
              ],
            },
            {
              id: "education",
              blocks: [
                {
                  type: "text_vizs",
                  data: {
                    mainText: "rec-kenya-education-intro-text_1",
                    embeds: [
                      {
                        title: "rec-kenya-education-text_1",
                        chartUrl: "rec-kenya-education-viz_1",
                      },
                      {
                        title: "rec-kenya-education-text_2",
                        chartUrl: "rec-kenya-education-viz_2",
                      },
                    ],
                  },
                },
                {
                  type: "quotes",
                  data: {
                    quotes: [
                      {
                        pic: "rec-kenya-education-quote-1-pic",
                        text: "rec-kenya-education-quote-1-text",
                        author: "rec-kenya-education-quote-1-author",
                      },
                    ],
                  },
                },
                {
                  type: "recommendationItem",
                  data: {
                    text: [
                      "rec-kenya-education-reco-1-1",
                      "rec-kenya-education-reco-1-2",
                    ],
                  },
                },
                {
                  type: "text_vizs",
                  data: {
                    mainText: "rec-kenya-education-intro-text_2",
                    embeds: [
                      {
                        title: "rec-kenya-education-text_3",
                        chartUrl: "rec-kenya-education-viz_3",
                      },
                    ],
                  },
                },
                {
                  type: "quotes",
                  data: {
                    quotes: [
                      {
                        pic: "rec-kenya-education-quote-2-pic",
                        text: "rec-kenya-education-quote-2-text",
                        author: "rec-kenya-education-quote-2-author",
                      },
                    ],
                  },
                },
                {
                  type: "recommendationItem",
                  data: {
                    text: [
                      "rec-kenya-education-reco-2-1",
                      "rec-kenya-education-reco-2-2",
                      "rec-kenya-education-reco-2-3",
                    ],
                  },
                },
              ],
            },
            {
              id: "economic",
              blocks: [
                {
                  type: "text_vizs",
                  data: {
                    mainText: "rec-kenya-economic-intro-text_1",
                    embeds: [
                      {
                        title: "rec-kenya-economic-text_1",
                        chartUrl: "rec-kenya-economic-viz_1",
                      },
                      {
                        title: "rec-kenya-economic-text_2",
                        chartUrl: "rec-kenya-economic-viz_2",
                      },
                      {
                        title: "rec-kenya-economic-text_3",
                        chartUrl: "rec-kenya-economic-viz_3",
                      },
                      {
                        title: "rec-kenya-economic-text_4",
                        chartUrl: "rec-kenya-economic-viz_4",
                      },
                    ],
                  },
                },
                {
                  type: "recommendationItem",
                  data: {
                    text: [
                      "rec-kenya-economic-reco_1-1",
                      "rec-kenya-economic-reco_1-2",
                    ],
                  },
                },
                {
                  type: "text_vizs",
                  data: {
                    mainText: "rec-kenya-economic-intro-text_2",
                    embeds: [
                      {
                        title: "rec-kenya-economic-text_5",
                        chartUrl: "rec-kenya-economic-viz_5",
                      },
                      {
                        title: "rec-kenya-economic-text_6",
                        chartUrl: "rec-kenya-economic-viz_6",
                      },
                      {
                        title: "rec-kenya-economic-text_7",
                        chartUrl: "rec-kenya-economic-viz_7",
                      },
                      {
                        title: "rec-kenya-economic-text_8",
                        chartUrl: "rec-kenya-economic-viz_8",
                      },
                      {
                        title: "rec-kenya-economic-text_9",
                        chartUrl: "rec-kenya-economic-viz_9",
                      },
                    ],
                  },
                },
                {
                  type: "recommendationItem",
                  data: {
                    text: ["rec-kenya-economic-reco_2"],
                  },
                },
              ],
            },
          ],
        },
        uganda: {
          topics: [
            {
              id: "id",
              blocks: [
                {
                  type: "text_vizs",
                  data: {
                    mainText: "rec-uganda-id-intro-text_1",
                    embeds: [
                      {
                        title: "rec-uganda-id-text_1",
                        chartUrl: "rec-uganda-id-viz_1",
                      },
                      {
                        title: "rec-uganda-id-text_2",
                        chartUrl: "rec-uganda-id-viz_2",
                      },
                      {
                        title: "rec-uganda-id-text_3",
                        chartUrl: "rec-uganda-id-viz_3",
                      },
                      {
                        title: "rec-uganda-id-text_4",
                        chartUrl: "rec-uganda-id-viz_4",
                      },
                      {
                        title: "rec-uganda-id-text_5",
                        chartUrl: "rec-uganda-id-viz_5",
                      },
                    ],
                  },
                },
                {
                  type: "recommendationItem",
                  data: {
                    text: [
                      "rec-uganda-id-reco-1-1",
                      "rec-uganda-id-reco-1-2",
                      "rec-uganda-id-reco-1-3",
                    ],
                  },
                },
                {
                  type: "text_vizs",
                  data: {
                    mainText: "rec-uganda-id-intro-text_2",
                    embeds: [
                      {
                        title: "rec-uganda-id-text_6",
                        chartUrl: "rec-uganda-id-viz_6",
                      },
                    ],
                  },
                },
                {
                  type: "recommendationItem",
                  data: {
                    text: ["rec-uganda-id-reco-2-1", "rec-uganda-id-reco-2-2"],
                  },
                },
              ],
            },
            {
              id: "education",
              blocks: [
                {
                  type: "text_vizs",
                  data: {
                    mainText: "rec-uganda-education-intro-text_1",
                    embeds: [
                      {
                        title: "rec-uganda-education-text_1",
                        chartUrl: "rec-uganda-education-viz_1",
                      },
                      {
                        title: "rec-uganda-education-text_2",
                        chartUrl: "rec-uganda-education-viz_2",
                      },
                      {
                        title: "rec-uganda-education-text_3",
                        chartUrl: "rec-uganda-education-viz_3",
                      },
                    ],
                  },
                },
                {
                  type: "recommendationItem",
                  data: {
                    text: ["rec-uganda-education-reco-1"],
                  },
                },
              ],
            },
            {
              id: "economic",
              blocks: [
                {
                  type: "text_vizs",
                  data: {
                    mainText: "rec-uganda-economic-intro-text_1",
                    embeds: [
                      {
                        title: "rec-uganda-economic-text_1",
                        chartUrl: "rec-uganda-economic-viz_1",
                      },
                      {
                        title: "rec-uganda-economic-text_2",
                        chartUrl: "rec-uganda-economic-viz_2",
                      },
                      {
                        title: "rec-uganda-economic-text_3",
                        chartUrl: "rec-uganda-economic-viz_3",
                      },
                    ],
                  },
                },
                {
                  type: "recommendationItem",
                  data: {
                    text: ["rec-uganda-economic-reco_1"],
                  },
                },
                {
                  type: "text_vizs",
                  data: {
                    mainText: "rec-uganda-economic-intro-text_2",
                    embeds: [
                      {
                        title: "rec-uganda-economic-text_4",
                        chartUrl: "rec-uganda-economic-viz_4",
                      },
                      {
                        title: "rec-uganda-economic-text_5",
                        chartUrl: "rec-uganda-economic-viz_5",
                      },
                      {
                        title: "rec-uganda-economic-text_6",
                        chartUrl: "rec-uganda-economic-viz_6",
                      },
                      {
                        title: "rec-uganda-economic-text_7",
                        chartUrl: "rec-uganda-economic-viz_7",
                      },
                      {
                        title: "rec-uganda-economic-text_8",
                        chartUrl: "rec-uganda-economic-viz_8",
                      },
                      {
                        title: "rec-uganda-economic-text_9",
                        chartUrl: "rec-uganda-economic-viz_9",
                      },
                      {
                        title: "rec-uganda-economic-text_10",
                        chartUrl: "rec-uganda-economic-viz_10",
                      },
                    ],
                  },
                },
                {
                  type: "recommendationItem",
                  data: {
                    text: ["rec-uganda-economic-reco_2"],
                  },
                },
              ],
            },
          ],
        },
      },
    };
  },
  mounted() {
    getGoogleSheetData(["recommendations"]).then((resp) => {
      this.content = resp;
    });
  },
  computed: {
    currentCountry() {
      return this.$route.params.country?.toLowerCase();
    },
    topics() {
      return this.dataStructure[this.currentCountry]?.topics || [];
    },
  },
  methods: {
    isOpen(topic) {
      return this.currentTopic.contains(topic);
    },
    toggleTopic(topic) {
      this.currentTopic = this.currentTopic !== topic ? topic : "";
    },
  },
};
</script>

<style lang="stylus">
.recommendations-list
  .intro-rec
    background-color: #c3dcdd
    .location-title
      margin-top: 40px
      width 50%
      max-width: 450px
      @media only screen and (max-width: 768px)
        width 70%
    .reco-title
      color: #D54938
      r-font-size(50,60)
      font-weight: bold
      text-align: center
      margin-top: 60px
    @media only screen and (max-width: 768px)
      min-height: auto
      background-image none
      padding 20px 0px
    .content
      text-align: left
      color: #fff
      background-image: url('~@/assets/reco-country-bg.png')
      background-position: right -50px
      background-repeat: no-repeat
      background-size: 50vw
      @media only screen and (max-width: 768px)
        text-align: center
        background-image: none
      .intro-title
        r-font-size(60,72)
        font-weight: 700;
        margin-bottom: 30px
        max-width 50%
        @media only screen and (max-width: 768px)
          font-size: 30px
          line-height: 40px
          max-width 100%
      .intro-text
        r-font-size(40,60)
        font-weight: 400;
        max-width 50%
        @media only screen and (max-width: 768px)
          font-size: 20px
          max-width 100%
          line-height: 30px


  .content-rec
    background-color: #c3dcdd
    color: #2F1F45
    background-image: url('~@/assets/rec-bg-pattern.svg')
    background-position: 50px 50px
    background-size: contain
    background-repeat: repeat-y
    .topic-rec
      margin-bottom: 3em
      .topic-title
        font-weight: 700;
        r-font-size(80,120);
        text-align: center;
        margin: 80px 0px
        @media only screen and (max-width: 768px)
          text-align left

  .topic-header
    background: #2F1F45;
    color #fff
    height: 120px
    border-radius: @height 0 0 0
    display: flex
    align-items: center
    cursor pointer
    margin-bottom: 2em
    .topic-header-title
      margin-left: 100px
      text-align: left
      font-weight: 600
      r-font-size(35,40)
      @media only screen and (max-width: 768px)
            margin-left 60px
    .toggle-icon
      margin-left: auto
      width: 40px
      min-width: 40px
      height: 40px
      margin-right: 40px
      position: relative;
      &:before,
      &:after
        content: "";
        position: absolute;
        background-color: white;
        transition: transform 0.25s ease-out;

      /* Vertical line */
      &:before
          top: 0;
          left: 50%;
          width: 8px;
          height: 100%;
          margin-left: -4px;

      /* horizontal line */
      &:after
          top: 50%;
          left: 0;
          width: 100%;
          height: 8px;
          margin-top: -4px;

      &.open
          &:before
            transform: rotate(90deg)
          &:after
            transform: rotate(180deg)
</style>
