<template>
  <section class="intro">
    <div class="logo">
      <!--<img src="~@/assets/intro.svg">-->
      <img src="~@/assets/intro-covid.svg" />
      <div class="yellow-block">
        Our covid-19 experiences
      </div>
    </div>
    <div class="content">
      <div class="intro">
        <div class="block bold" v-html="$t('intro_par_1')"></div>
        <div class="block" v-html="$t('intro_par_2')"></div>
        <div class="block" v-html="$t('intro_par_3')"></div>
        <div class="block" v-html="$t('intro_par_4')"></div>
        <div class="facts">
          <div class="fact">
            <img src="~@/assets/intro_fact_1.svg" />
            <div class="fact-text" v-html="$t('intro_fact_1')"></div>
            <div class="fact-source">
              <a :href="$t('intro_link_1')">Source</a>
            </div>
          </div>
          <div class="fact">
            <img src="~@/assets/intro_fact_2.svg" />
            <div class="fact-text" v-html="$t('intro_fact_2')"></div>
            <div class="fact-source">
              <a :href="$t('intro_link_2')">Source</a>
            </div>
          </div>
          <div class="fact">
            <img src="~@/assets/intro_fact_3.svg" />
            <div class="fact-text" v-html="$t('intro_fact_3')"></div>
            <div class="fact-source">
              <a :href="$t('intro_link_3')">Source</a>
            </div>
          </div>
        </div>

        <div class="quote">
          <div class="quote-text" v-html="$t('intro_quote')"></div>
          <div class="quote-author" v-html="$t('intro_quote_author')"></div>
          <div class="quote-source" v-html="$t('intro_quote_ref')"></div>
        </div>

        <div class="block" v-html="$t('intro_par_6')"></div>
        <div class="block bigger" v-html="$t('intro_par_7')"></div>
        <!--<div class="block">
          <img class="intro-dots-image" src="~@/assets/intro_dots.svg">
         </div>-->
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "Intro",
  props: ["content"],
};
</script>

<style lang="stylus">
.intro
  background: #2F1F45
  color #ffffff
  padding-bottom 10vw
  .logo
    width: 80%
    margin-bottom: 0px
    margin: auto
    background: linear-gradient(180deg, #479095 0%, rgba(195, 220, 221, 0) 100%);
    @media only screen and (max-width: 768px)
      width: 100%
    img
      padding: 40px 0
      width 80%
      max-width 1200px
      @media only screen and (max-width: 768px)
        width 95%
    .yellow-block
      width: 100%
      background: #ECAE40
      r-font-size(45,100)
      color: #000000
      font-weight: bold
      @media only screen and (max-width: 768px)
        r-font-size(35,100)

  .content
    @media only screen and (max-width: 768px)
      padding 0px
  .facts
    margin 60px 0px
    display: flex
    justify-content: space-between
    @media only screen and (max-width: 768px)
      flex-direction: column
      align-items: center

    .fact
      width: 30%
      @media only screen and (max-width: 768px)
        width: 65%
        margin-bottom: 50px
      img
        width 100%
      .fact-text
        font-weight: 700
        r-font-size(20,30)
        padding: 0px 25px
        margin-top: -80px
      .fact-source
        a
          font-family: Poppins;
          r-font-size(15,23)
          font-style: normal;
          font-weight: 500;
          color #fff

  .quote
    background: linear-gradient(0deg, #2F1F45 0.17%, #479095 99.99%);
    border-radius: 0px 150px 0px 150px
    padding 60px
    .quote-text
      r-font-size(30, 45)
      font-style: normal;
      font-weight: 700;
      text-align: left;
      margin-bottom: 20px
    .quote-author
      r-font-size(25, 37)
      font-weight: 700;
      text-align: right;
    .quote-source
      r-font-size(25, 37)
      font-weight: 400;
      text-align: right;

  a, a:visited
    color #fff

  .bigger
    r-font-size(28, 42)
    font-weight: 400;
  .intro-dots-image
    margin-top 50px
    width: 100%
</style>
