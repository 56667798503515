<template>
  <div v-if="!content || content.length == 0" class="loading">
    <div class="loader-text">#TheFutureWithWakimbizi</div>
    <Loader></Loader>
  </div>
  <div v-else id="app">
    <header
      v-bind:class="[$route.name]"
      v-click-outside="() => (showMobileMenu = false)"
    >
      <div class="header-content content">
        <div class="title-header">
          <router-link to="/" class="home-link"
            >#TheFutureWithWakimbizi</router-link
          >
          <Social></Social>
        </div>
        <div class="hambuger-menu" @click="() => (showMobileMenu = true)"></div>
        <nav v-bind:class="[showMobileMenu && 'open']">
          <img
            class="logo-compact"
            src="~@/assets/logo-compact.svg"
            @click="$router.push('/')"
          />
          <div class="icon-close" @click="() => (showMobileMenu = false)"></div>

          <router-link to="/recommendations/kenya"
            ><span @click="() => (showMobileMenu = false)">{{
              $t("link_reco_kenya")
            }}</span></router-link
          >
          <router-link to="/recommendations/uganda">
            <span @click="() => (showMobileMenu = false)">
              {{ $t("link_reco_uganda") }}
            </span></router-link
          >
          <router-link to="/about"
            ><span @click="() => (showMobileMenu = false)">{{
              $t("link_about")
            }}</span></router-link
          >
          <router-link to="/story"
            ><span @click="() => (showMobileMenu = false)">{{
              $t("link_our_story")
            }}</span></router-link
          >
          <!--<router-link to="/download"
            ><span @click="() => (showMobileMenu = false)">{{
              $t("link_download")
            }}</span></router-link>-->
        </nav>
      </div>
    </header>
    <router-view :generalContent="content" />
    <footer>
      <div class="footer-content content">
        <div class="footer-par" v-html="$t('footer_par_1')"></div>
        <div class="partner-logo">
          <a href="https://youthvoicescommunity.org/" target="_blank"
            ><img class="logo" src="~@/assets/YVC-Logo-White.svg"
          /></a>
          <a href="https://www.yarid.net/" target="_blank"
            ><img class="logo" src="~@/assets/YARID-Logo-White.svg"
          /></a>
        </div>
        <div class="footer-blocks">
          <div class="footer-block">
            <div class="keep">Keep up to date with</div>
            <div class="partener-social">
              <div class="social-block">
                <div class="social-title">YVC</div>
                <div class="social-footer">
                  <a
                    target="_blank"
                    href="https://www.facebook.com/youthvoicesnairobi/"
                    class="social-icon fb"
                  ></a>
                  <a
                    target="_blank"
                    href="https://twitter.com/youthVoicesCom"
                    class="social-icon tw"
                  ></a>
                  <a
                    target="_blank"
                    href="https://www.instagram.com/youthvoices_community/"
                    class="social-icon insta"
                  ></a>
                  <a
                    href="mailto:info@youthvoicescommunity.org"
                    class="social-icon mail"
                  ></a>
                </div>
              </div>
              <div class="social-block">
                <div class="social-title">YARID</div>
                <div class="social-footer">
                  <a
                    target="_blank"
                    href="https://www.facebook.com/YaridUganda"
                    class="social-icon fb"
                  ></a>
                  <a
                    target="_blank"
                    href="https://twitter.com/YARIDUganda"
                    class="social-icon tw"
                  ></a>
                  <a
                    target="_blank"
                    href="https://ug.linkedin.com/company/yarid-uganda"
                    class="social-icon linkedin"
                  ></a>
                  <a
                    target="_blank"
                    href="https://www.instagram.com/YARIDUganda"
                    class="social-icon insta"
                  ></a>
                  <a href="mailto:info@yarid.net" class="social-icon mail"></a>
                </div>
              </div>
            </div>
          </div>
          <div class="footer-block partners">
            <div class="partner-block">
              <div class="parter-title">Designed and developed by</div>
              <a href="https://www.data4chan.ge/" target="_blank"
                ><img
                  class="logo d4c"
                  src="~@/assets/Data4Change-Logo-White.svg"
              /></a>
            </div>
            <div class="partner-block">
              <div class="parter-title">In partnership with</div>
              <a href="https://defyhatenow.org/" target="_blank"
                ><img
                  class="logo dhn"
                  src="~@/assets/DefyHateNow-Logo-White.svg"
              /></a>
            </div>
            <div class="partner-block">
              <div class="parter-title">Made possible with funding from</div>
              <div class="logo-list">
                <a href="https://omidyar.com/" target="_blank"
                  ><img
                    class="logo omid"
                    src="~@/assets/OmidyarNetwork-Logo-White.svg"
                /></a>
                <a
                  href="https://www.opensocietyfoundations.org/"
                  target="_blank"
                  ><img class="logo osf" src="~@/assets/osf-logo.png"
                /></a>
                <a href="https://hewlett.org/" target="_blank"
                  ><img class="logo hf" src="~@/assets/HF-logo.png"
                /></a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
import { getGoogleSheetData } from "@/api";
import Social from "./components/Social";
import ClickOutside from "vue-click-outside";
import Loader from "./components/Loader.vue";

export default {
  name: "App",
  components: { Social, Loader },
  data() {
    return {
      content: [],
      showMobileMenu: false,
    };
  },
  mounted() {
    getGoogleSheetData(["general"]).then((resp) => {
      this.content = resp;
    });
  },
  directives: {
    ClickOutside,
  },
};
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Bungee&family=Lora:wght@400;700&family=Poppins:ital,wght@0,300;0,400;0,600;0,700;1,300&display=swap");
</style>

<style lang="stylus">
body
  margin: 0
  padding: 0
  overflow hidden
  height: 100vh
  overflow: scroll
.loading
  height: 100vh
  width: 100vw
  background: #2F1F45
  display: flex
  justify-content: center
  align-items: center
  font-family 'Poppins', sans-serif;
  -webkit-font-smoothing antialiased
  -moz-osx-font-smoothing grayscale
  text-align: center
  overflow-x: hidden;
  color: #fff
  flex-direction: column;
#app
  font-family 'Poppins', sans-serif;
  -webkit-font-smoothing antialiased
  -moz-osx-font-smoothing grayscale
  text-align: center
  scroll-behavior: smooth;
  overflow-x: hidden;
  header
    background: #2F1F45
    height 120px
    display flex
    align-items center
    color #fff
    a, a:visited
      color #fff
      text-decoration none
    &.landing
      background: #479095
    &.recommendations
      background: #479095
    &.about
      background: #479095
    &.story
      background: #479095

    .header-content
      display flex
      font-weight 700
      r-font-size(18)
      @media only screen and (max-width: 768px)
        align-items flex-start
      .social
        margin-top 10px
      .title-header
        .home-link
          &:hover, &.router-link-exact-active
            border-bottom: 4px solid
      .hambuger-menu
        display none
        @media only screen and (max-width: 768px)
          margin-left auto
          display block
          background-image: url('~@/assets/hamburger-menu.svg')
          background-position: center center
          background-size: 20px 16px
          background-repeat: no-repeat
          width 20px
          height: 16px
      nav
        margin-left auto
        text-align: right
        @media only screen and (max-width: 768px)
          position: fixed
          right: -70vw
          top 0px
          width 70vw
          background: #2F1F45
          text-align left
          r-font-size(24)
          height 100vh
          transition: all 0.5s ease
          border-left: 1px solid #fff
          &.open
            right: 0px
          a, a:visited
            color: #fff !important;

        .logo-compact
          display: none
          @media only screen and (max-width: 768px)
            display block
            width: 50%
            margin: 20px
            margin-bottom:  40px
        .icon-close
          display: none
          @media only screen and (max-width: 768px)
            display block
            position: absolute
            top 20px
            right: 20px
            cursor pointer
            width: 20px
            height: 20px
            background-image url('~@/assets/icon-close.svg')
            background-size: cover

        a
          display: inline-block;
          @media only screen and (max-width: 768px)
            display block
            margin: 20px
            padding-top: 5px
            border-top: 1px solid #ECAE40
            margin-right: 40px
          span
            border-bottom: 4px solid transparent
          &:hover, &.router-link-active
            span
              border-bottom: 4px solid
              @media only screen and (max-width: 768px)
                border-bottom 0px
                color: #ECAE40
          &:not(:last-child)
            &:after
              content: '|'
              padding 0px 7px
              @media only screen and (max-width: 768px)
                content: ''
                padding 0px 0px

          &.has-submenu
            position relative
            .submenu
              display: none
              position: absolute
              flex-direction: column
              top: 20px
              align-items: flex-start;
              @media only screen and (max-width: 768px)
                display: flex
                position: relative
                width 100%
                top: 0px
                margin 10px
                a
                  border: none
                  margin: 8px
              a
                padding 7px 0px
                &:after
                  content: ''
                  padding 0px 0px

            &:hover
              .submenu
                display: flex



  footer
    background-color: #2F1F45
    .footer-content
      color: #fff
      display: flex;
      flex-direction: column;
      align-items: center;
      max-width 800px
      .footer-par
        font-weight: 700
        r-font-size(28,42)
        text-align: center
        padding-bottom: 30px
        width: 100%
      .partner-logo
        text-align: center
        border-bottom: 1px solid #fff
        padding-bottom: 20px
        width: 100%
        .logo
          height: 70px
          margin: 15px
          @media only screen and (max-width: 768px)
            height: 50px
      .footer-blocks
        display: flex
        width: 100%
        margin-top: 30px
        @media only screen and (max-width: 768px)
          flex-direction: column
        .footer-block
          flex: 0 0 50%
          text-align: left
          margin-left 45px
          @media only screen and (max-width: 768px)
             margin-left 0px
          &:not(:last-child)
            border-right 1px solid #fff
            margin-left 0px
            @media only screen and (max-width: 768px)
              border-bottom: 1px solid #fff
              border-right: 0px
              padding-bottom: 30px
              margin-bottom: 30px
          .keep
            r-font-size(24,36)
            font-weight: 700
            margin-bottom: 20px
          .partener-social
            display flex
            .social-block
              margin-right: 40px
              @media only screen and (max-width: 768px)
                margin-right: 20px
          .social-footer
            display flex
            margin-top 0.8em

            .social-icon
              height: 30px
              width 30px
              margin-right 10px
              background-position: center center
              background-repeat: no-repeat
              background-size: contain
              cursor pointer
              display block
              &:last-child
                margin-right 0px
              &.fb
                background-image: url('~@/assets/social-fb.svg')
              &.tw
                background-image: url('~@/assets/social-tw.svg')
              &.wp
                background-image: url('~@/assets/social-wp.svg')
              &.insta
                background-image: url('~@/assets/social-insta.svg')
              &.mail
                background-image: url('~@/assets/social-mail.svg')
              &.linkedin
                background-image: url('~@/assets/social-linkedin.svg')

          &.partners
            display: flex
            flex-wrap: wrap
            .partner-block
              r-font-size(16,24)
              font-weight: 600
              margin-right: 40px
              .logo-list
                margin-top: 10px
                display: flex
                gap: 25px
                @media only screen and (max-width: 768px)
                  flex-direction: column
                  gap: 10px
                img
                  height:40px
              .logo
                margin-bottom: 2em
              .d4c
                margin-top: 24px
              .omid
                height: 34px

img
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
  user-drag: none;

.content
  width 70%
  max-width 1100px
  margin 0 auto
  padding 40px 0px
  @media only screen and (max-width: 768px)
    width 90%
    margin 0 auto
    padding 20px 0px
.page-loader
  height: 100vh
  width: 100vw
  background-color: rgba(120,120,120,0.3)
  position: absolute
  top 0px
  display: flex
  align-items: center
  justify-content: center
.button
  font-weight: 700
  border 3px solid #fff
  border-radius 50px
  height 40px
  cursor pointer
  display flex
  align-items center
  justify-content center
  padding 5px 15px

@media print
  overflow visible
  #app
    height: auto
</style>
