<template>
  <div class="rec-block-viz">
    <div class="content">
      <div class="big-text" v-html="$t(data.mainText)"></div>

      <FlourishEmbed
        v-if="item.chartUrl"
        v-for="item in data.embeds"
        :text="$t(item.title)"
        :chartUrl="$t(item.chartUrl)"
        :key="item.chartUrl"
        :id="item.chartUrl"
        :frameHeight="$t(item.chartUrl + '_height')"
      ></FlourishEmbed>
    </div>
  </div>
</template>

<script>
import FlourishEmbed from "./FlourishEmbed.vue";

export default {
  name: "RecBlockViz",
  components: { FlourishEmbed },
  props: ["data", "content"],
};
</script>

<style lang="stylus">
.rec-block-viz
  margin-bottom: 50px
  .content
    width: 50%
    text-align: left
    r-font-size(24,30)
    @media only screen and (max-width: 768px)
      width: 80%
    .big-text
      font-weight bold
      margin-bottom: 50px
</style>
