<template>
  <div class="view about">
    <div v-if="!content || content.length == 0" class="page-loader">
      <Loader></Loader>
    </div>
    <div class="intro-about">
      <div class="content">
        <div class="intro-title">{{ $t("about_title") }}</div>
        <div class="intro-text">{{ $t("about_intro") }}</div>
      </div>
    </div>
    <div class="content-about">
      <div class="content small-50">
        <img class="content-tile" src="~@/assets/raising-voices.svg" />
        <div class="about-text">
          <div v-html="$t('main_text_1')" />
          <div class="image-composition">
            <img
              src="/images/pic-1.jpg"
              alt="The Future with Wakimbizi - picture 1"
            />
            <img
              src="/images/pic-2-3.png"
              alt="The Future with Wakimbizi - picture 2"
            />
            <img
              src="/images/pic-4.jpg"
              alt="The Future with Wakimbizi - picture 4"
            />
            <img
              src="/images/pic-5.jpg"
              alt="The Future with Wakimbizi - picture 5"
            />
            <img
              src="/images/pic-6.jpg"
              alt="The Future with Wakimbizi - picture  6"
              class="wide"
            />
          </div>
          <div v-html="$t('main_text_2')"></div>
        </div>
      </div>
      <div class="content">
        <img class="separator" src="~@/assets/separator-yellow.svg" />
        <div>
          <div class="section-title">{{ $t("who_we_are") }}</div>
          <div class="credit-teams whoweare">
            <div class="team">
              <a href="https://youthvoicescommunity.org/" target="_blank"
                ><img class="logo" src="~@/assets/YVC-Logo-Color.svg"
              /></a>
              <span v-html="$t('yvc_about')"></span>
            </div>
            <div class="team">
              <a href="https://www.yarid.net/" target="_blank"
                ><img class="logo" src="~@/assets/YARID-Logo-Color.svg"
              /></a>
              <span v-html="$t('yarid_about')"></span>
            </div>
          </div>
        </div>
        <img class="separator" src="~@/assets/separator-yellow.svg" />
        <div>
          <div class="section-title">{{ $t("partner_org") }}</div>
          <div class="section-text">{{ $t("partner_org_text") }}</div>
          <div class="credit-teams grid">
            <div class="team center">
              <a
                href="https://www.facebook.com/p/WEDI-Womens-Empowerment-and-Development-Initiative-100077378374137/"
                target="_blank"
              >
                <img class="logo" src="~@/assets/logo-wedi.png" />
                <span v-html="$t('partner_wedi')"></span>
              </a>
            </div>
            <div class="team center">
              <a
                href="https://www.facebook.com/p/Refugee-Brotherhood-100065034750333/"
                target="_blank"
              >
                <img class="logo" src="~@/assets/logo-ref.png" />
                <span v-html="$t('partner_ref')"></span>
              </a>
            </div>
            <div class="team center">
              <a href="https://bortopragroup.org/" target="_blank">
                <img class="logo" src="~@/assets/logo-bortopra.png" />
                <span v-html="$t('partner_bortopra')"></span>
              </a>
            </div>
            <div class="team center">
              <a href="https://www.facebook.com/LAFRIKANACB0/" target="_blank">
                <img class="logo" src="~@/assets/logo-lafrikana.png" />
                <span v-html="$t('partner_lafrikana')"></span>
              </a>
            </div>
            <div class="team center">
              <a
                href="https://www.instagram.com/tawakal_youth_organization_"
                target="_blank"
              >
                <img class="logo" src="~@/assets/logo-tawakal.png" />
                <span v-html="$t('partner_tawakal')"></span>
              </a>
            </div>
          </div>
        </div>
        <img class="separator" src="~@/assets/separator-yellow.svg" />
        <div>
          <div class="section-title">{{ $t("people_title") }}</div>
          <div class="section-text">{{ $t("people_intro") }}</div>
          <div class="people">
            <div class="person" v-for="person in team" :key="person.pic">
              <img :src="`/pics/${person.pic}_pic.png`" />
              <div class="name">{{ person["Name"] }}</div>
              <div class="name">{{ person[$currentKey("Role")] }}</div>
              <div class="bio">{{ person[$currentKey("Bio")] }}</div>
            </div>
          </div>
          <div class="section-bold">{{ $t("people_collective") }}</div>
          <img class="all-dots" src="~@/assets/all-dots-917.svg" />
        </div>
        <img class="separator" src="~@/assets/separator-yellow.svg" />
        <div>
          <div class="section-title">{{ $t("orgs_title") }}</div>
          <div class="credit-teams">
            <div class="team inline">
              <a href="https://www.data4chan.ge/" target="_blank"
                ><img class="logo-credit d4c" src="~@/assets/D4C-logo-black.png"
              /></a>
              <span v-html="$t('d4c_about')"></span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getGoogleSheetData } from "@/api";
import Loader from "../components/Loader.vue";

export default {
  name: "Recommendations",
  props: ["generalContent"],

  data() {
    return {
      content: [],
      team: [],
    };
  },
  mounted() {
    getGoogleSheetData(["about"]).then((resp) => {
      this.content = resp;
    });
    getGoogleSheetData(["about_people"]).then((resp) => {
      this.team = resp;
    });
  },
  components: { Loader },
};
</script>

<style lang="stylus">
.about
  .intro-about
    background-color: #2F1F45
    background-image: url('~@/assets/about-bg.svg')
    background-position: right top
    background-repeat: no-repeat
    background-size: contain
    min-height: 600px
    @media only screen and (max-width: 768px)
      min-height: auto
      background-image none
      padding 20px 0px
    .content
      text-align: left
      color: #fff
      .intro-title
        r-font-size(60,72);
        font-weight: 700;
        margin-bottom: 30px
        margin-top: 50px
        max-width 50%
        @media only screen and (max-width: 768px)
          font-size: 30px
          line-height: 40px
          max-width 100%
          margin-top: 0px

      .intro-text
        r-font-size(40,60)
        font-weight: 400;
        max-width 50%
        @media only screen and (max-width: 768px)
          font-size: 20px
          max-width 100%
          line-height: 30px


  .content-about
    background: #c3dcdd
    color: #2F1F45
    text-align: left
    .content
      width: 60%
      &.small-50
        width: 50%
        @media only screen and (max-width: 768px)
          width 90%
      @media only screen and (max-width: 768px)
        width 90%
    .content-tile
      width: 60vw
      margin-top: 1em
      margin-bottom: 1em
      @media only screen and (max-width: 768px)
        width: 80vw
    .about-text
      margin: 0 auto
      max-width 900px
      padding 80px
      text-align: left
      .image-composition
        display: flex
        justify-content: space-evenly
        flex-wrap: wrap
        margin: 40px 0px
        img
          width: 48%
          margin-bottom: 2%
          &.wide
            width: 100%

      @media only screen and (max-width: 768px)
        max-width auto
        padding 0px
    a, a:visited
      color #2F1F45
  .credit-teams
    display flex
    padding 20px 60px
    padding-bottom 115px
    gap: 50px
    justify-content: space-evenly
    &.whoweare
      padding 20px 0px
    &.grid
      display: grid;
      grid-template-columns: auto auto auto;


    @media only screen and (max-width: 768px)
      flex-direction: column
      padding 10px
      &.grid
        grid-template-columns: auto

    .team
      text-align: left
      flex 1 1 50%
      &.center
        text-align: center
        display: flex
        flex-direction: column
        align-items: center
        a
          display: flex
          flex-direction: column
          align-items: center
          font-weight 600

      &.inline
        flex 1 1 100%
        text-align: left
        display: flex
        flex-direction: row
        gap: 80px
        @media only screen and (max-width: 768px)
          flex-direction: column
          padding 10px
          align-items: center
        span
          flex 1 0 50%
        img
          width 100%
          margin: 0px

      @media only screen and (max-width: 768px)
        width: 100%
        margin-right: 0px
        margin-left: 0px
        margin-bottom: 50px

      .logo
        display block
        margin-bottom 20px
        height: 80px
        @media only screen and (max-width: 768px)
          height: 80px
          display: block
      .logo-credit
        display block
        margin-bottom 10px
        @media only screen and (max-width: 768px)
          width: 70%
          height auto
          display: block

  .section-title
    font-weight: 700
    r-font-size(40,52)
    text-align: center
    color #2F1F45
    margin-bottom: 20px
  .section-text
    width 80%
    text-align: center
    margin: 30px auto
    r-font-size(20,30)
    font-weight: 500;
  .section-bold
    r-font-size(30,42)
    font-weight: 700;
    text-align: center;
  .all-dots
    margin: 30px auto
    width: 60%
    display: block
  .people
    display: grid;
    grid-template-columns 1fr 1fr 1fr
    grid-column-gap 60px
    grid-row-gap 60px
    margin-bottom 60px
    @media only screen and (max-width: 1024px)
      grid-template-columns 1fr 1fr
    @media only screen and (max-width: 768px)
      grid-template-columns 1fr
    .person
      text-align: center
      img
        width: 100%
      .name
        r-font-size(20,30)
        font-weight 600
  .separator
    width 100%
    display: block
    margin: 30px 0px
</style>
