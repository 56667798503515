<template>
  <div class="circle-plot">
    <svg ref="circleSVG"></svg>
    <div class="number" v-if="percentage">{{percentage}}%</div>
    <div class="label" v-html="label"></div>

  </div>
</template>

<script>
import { pack,hierarchy, rollup, reduce } from 'd3';
import { select } from 'd3-selection';
import { forceSimulation, forceCenter, forceCollide, forceClusterCollision, forceRadial, forceX, forceY } from 'd3-force';

import {scaleOrdinal} from 'd3-scale'
import { transition } from 'd3-transition';
import { timeFormat } from 'd3-time-format';
import {groups} from 'd3-array';
import {csv as d3_csv} from 'd3-fetch'
import {autoType} from 'd3-dsv'
import {getSVG} from '../utils/export'

export const DEFAULT_WIDTH = 300;
export const DEFAULT_HEIGHT = 300;

export default {
  name: 'CiclePlot',
  props: ['numDots', 'percentage', 'label'],
  data () {
    return {
    }
  },
  mounted () {
    this.width = DEFAULT_WIDTH;
    this.height = DEFAULT_HEIGHT;
    this.drawChart()
  },
  methods: {
    
    drawChart() {
      let nodes = Array.from(Array(this.numDots).keys()).map(i => {
        return {
          index: i
        }
      })
      const center = [this.width / 2, this.height / 2];
      const svg = select(this.$refs.circleSVG)
      . attr("viewBox", [0, 0, this.width, this.height]);

      const node = svg.append("g")
        .selectAll("circle")
        .data(nodes)
        .join("circle")
          .attr("r", 4)
          .attr("fill", '#2f1f45')
          .attr("cx",center[0]).attr("cy", center[1]);

      const tick = () => {
        node
        //.transition(200)
        .attr("cx", d => d.x).attr("cy", d => d.y);
      }
      const simulation = forceSimulation(nodes)
          .force("collide", forceCollide().radius(7).strength(1))
          .force("center", forceCenter().x(center[0]).y(center[1]))
          .force("x", forceX(center[0]).strength(0.1))
          .force("y", forceY(center[1]).strength(0.1))
          .on("tick", tick)
          

      // show the initial arrangement
      simulation.alpha(0.1).restart();
    },
  },

}
</script>

<style lang="stylus" scoped>
.circle-plot
  text-align: center
  width 50%
  svg
    width 400px
    height 400px
  .number
    r-font-size(50,70)
    font-weight: 400
  .label
    r-font-size(30,45)
</style>