<template>
  <div class="reco-navigation-block content">
    <div class="big-text" v-if="mode == 'general'">
      {{ $t("rec-bit-about-us_out_text") }}
    </div>
    <div class="reco-nav-blocks">
      <div class="reco-nav-block kenya" v-if="mode != 'kenya'">
        <router-link to="/recommendations/kenya" class="button">{{
          $t("rec-nav-button-kenya")
        }}</router-link>
        <img
          class="location-title"
          src="~@/assets/kenya-reco.svg"
          @click="$router.push('/recommendations/kenya')"
        />
      </div>
      <div class="reco-nav-block uganda" v-if="mode != 'uganda'">
        <router-link to="/recommendations/uganda" class="button">{{
          $t("rec-nav-button-uganda")
        }}</router-link>
        <img
          class="location-title"
          src="~@/assets/uganda-reco.svg"
          @click="$router.push('/recommendations/uganda')"
        />
      </div>
      <div class="reco-nav-block general" v-if="mode != 'general'">
        <router-link to="/recommendations" class="button">{{
          $t("rec-nav-button-general")
        }}</router-link>
        <img
          class="location-title"
          src="~@/assets/reco-nav-image-general.svg"
          @click="$router.push('/recommendations')"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "RecNavigationModule",
  props: ["content", "mode"],
};
</script>

<style lang="stylus">

.reco-navigation-block
  padding 60px 30px
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #E6C683 50%, rgba(255, 255, 255, 0) 100%);
  text-align: center
  @media only screen and (max-width: 768px)
    padding 15px
  .big-text
    r-font-size(30,35)
    width: 70%
    text-align: center
    margin-bottom: 50px
    margin-left: 15%
  .reco-nav-blocks
    display: flex
    justify-content: space-evenly;
  .reco-nav-block
    width: 40%
    .location-title
        width: auto
        height: 15vw
        cursor pointer
        @media only screen and (max-width: 768px)
          height: 25vw
    .button
      text-decoration: none
      color: #fff
      background: #2F1F45
      border-color: #2F1F45
      filter: drop-shadow(0px 4px 4px rgba(0,0,0,0.25));
      margin-bottom: 50px
      r-font-size(20, 25)
</style>
