<template>
  <section class="look-ahead" id="look-ahead">
    <div class="content">
      <div class="title-block">
        {{$t('looking_title')}}
      </div>
      <div class="par" v-html="$t('looking_par')"></div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'LookAhead',
  props: ['content'],
}
</script>

<style lang="stylus" scoped>
#look-ahead
  background: #479095;
  background-image: url('~@/assets/looking-ahead-bg.svg')
  background-position: center 40px
  background-repeat: no-repeat
  background-size: 90%
  text-align left
  color #FFF
  padding 50px 0

  .title-block
    display flex
    align-items center
    font-weight 700
    r-font-size(75,100)
    margin-bottom 50px
  .par
    font-weight 700
    r-font-size(30,45)

</style>