<template>
  <section class="aboutus" id="aboutus">
    <div class="content">
      <!--<img class="section-title" src="~@/assets/about-us-title.svg">
      <div class="block" v-html="$t('about_map')"></div>
      <AfricaMap class="map"></AfricaMap>-->
      <img class="map" src="~@/assets/about-us-map.svg">
      <!--<img class="facts" src="~@/assets/about-us-facts.svg">-->

    </div>
  </section>
</template>

<script>
import AfricaMap from '../../components/AfricaMap'

export default {
  name: 'AboutUsMap',
  props: ['content'],
  components: {AfricaMap}
}
</script>

<style lang="stylus" scoped>
#aboutus
  background: #ECAE40;
  .content
    width 80%
  img
    width 100%
    &.map
      margin-top -15vw
      margin-bottom 10vw

</style>