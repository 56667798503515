<template>
  <section class="survey-results" id="survey-results">
    <div class="content">
      <img class="survey-title" src="~@/assets/survey-title.svg">
      <div class="text-block">{{$t('survey_result_intro')}}</div>
      <FlourishViz :vizId="'viz_intro'" :steps="$list('viz_intro')"></FlourishViz>

      <div class="text-source"><b>Source</b>: YARID & YVC Community survey 2021</div>
    </div>
  </section>
</template>

<script>
import FlourishViz from '../../components/FlourishViz'

export default {
  name: 'SurveyResults',
  props: ['content'],
  components: {FlourishViz}
}
</script>

<style lang="stylus" scoped>
#survey-results
  background: #c3dcdd;
  text-align left
  color #2F1F45
  padding-bottom: 40px
  .survey-title
    width 50vw
    margin-top -8vw
    margin-bottom 20px
  .text-block
    r-font-size(28,42)
    font-weight 400
  .text-source
    r-font-size(14,21)
    font-weight 400
    margin-top: 40px
    width: 100%
    text-align: center
</style>