<template>
  <div class="rec-topic-item">
    <div class="content">
      <div class="topic-header" @click="isOpen = !isOpen">
        <div class="topic-header-title">
          {{ $t("rec_" + topic.id + "_title") }}
        </div>
        <div v-bind:class="[{ open: isOpen }, 'toggle-icon']"></div>
      </div>
    </div>
    <div class="recs" v-show="isOpen">
      <div
        class="rec-block"
        v-for="(block, bIndex) in topic.blocks"
        :key="topic.id + bIndex"
      >
        <RecBlockViz
          v-if="block.type == 'text_vizs'"
          :data="block.data"
          :content="content"
        />
        <RecBlockQuotes
          v-if="block.type == 'quotes'"
          :data="block.data"
          :content="content"
        />
        <RecBlockReco
          v-if="block.type == 'recommendationItem'"
          :data="block.data"
          :content="content"
        />
      </div>
    </div>
  </div>
</template>

<script>
import RecBlockViz from "../components/RecBlockViz.vue";
import RecBlockQuotes from "../components/RecBlockQuotes.vue";
import RecBlockReco from "../components/RecBlockReco.vue";
export default {
  name: "RecTopicItem",
  props: ["topic", "content", "startOpen"],
  components: { RecBlockViz, RecBlockQuotes, RecBlockReco },
  data() {
    return {
      isOpen: this.startOpen,
    };
  },
};
</script>

<style lang="stylus"></style>
