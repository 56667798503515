<template>
  <div class="flourish-container">
    <div class="text">{{ text }}</div>
    <div
      v-if="chartID"
      :key="chartID"
      ref="myChart"
      class="flourish-embed flourish-chart"
      :data-src="`visualisation/${chartID}`"
    ></div>
  </div>
</template>

<script>
export default {
  name: "FlourishEmbed",
  props: ["text", "chartUrl", "frameHeight"],
  computed: {
    chartID() {
      let id = "";
      if (this.chartUrl) {
        let url = new URL(this.chartUrl);
        if (url && url.pathname) {
          id = url.pathname.split("/")[2];
        }
      }
      return id;
    },
  },
  mounted() {
    this.$nextTick(() => {
      if (this.chartID && this.$refs.myChart) {
        Flourish.loadEmbed(this.$refs.myChart);
      }
    });
  },
  watch: {
    chartID(id) {
      this.$nextTick(() => {
        if (id && this.$refs.myChart) {
          Flourish.loadEmbed(this.$refs.myChart);
        }
      });
    },
  },
};
</script>

<style lang="stylus">
.flourish-container
  text-align: left
  margin-bottom: 50px

  .text
    r-font-size(24,32)

  .iframe-container
    position: relative;
    overflow: hidden;
    width: 100%;

    iframe
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      width: 100%;
      height: 100%;
</style>
