<template>
  <div class="social">
    <ShareNetwork
      class="social-icon fb"
      network="facebook"
      :url="shareUrl"
      :title="text"
      :hashtags="hash"
    >
    </ShareNetwork>
    <ShareNetwork
      class="social-icon tw"
      network="twitter"
      :url="shareUrl"
      :title="text"
      :hashtags="hash"
    >
    </ShareNetwork>
    <ShareNetwork
      class="social-icon wp"
      network="whatsapp"
      :url="shareUrl"
      :title="text"
      :hashtags="hash"
    >
    </ShareNetwork>
  </div>
</template>

<script>
export default {
  name: "Social",
  computed: {
    shareUrl() {
      return window.location.origin;
    },
    text() {
      return "The Future with Wakimbizi: Raising Voices for Refugees. Visit us at ";
    },
    hash() {
      return "TheFutureWithWakimbizi";
    },
  },
};
</script>

<style lang="stylus">
.social
  display flex
  .social-icon
    height 34px
    width 34px
    margin-right 12px
    background-position: center center
    background-repeat: no-repeat
    background-size: contain
    cursor pointer
    @media only screen and (max-width: 768px)
      margin-right 10px
    &:last-child
      margin-right 0px
    &.fb
      background-image: url('~@/assets/social-fb.svg')
    &.tw
      background-image: url('~@/assets/social-tw.svg')
    &.wp
      background-image: url('~@/assets/social-wp.svg')
</style>
