import Vue from "vue";
import VueRouter from "vue-router";

import Landing from "@/views/Landing";
import Story from "@/views/Story";
import About from "@/views/About";
import Recommendations from "@/views/Recommendations";
import Download from "@/views/Download";
import RecommendationsList from "@/views/RecommendationsList";
import Documents from "@/views/Documents";

Vue.use(VueRouter);

export default new VueRouter({
  routes: [
    {
      path: "/about",
      name: "about",
      component: About,
    },
    {
      path: "/recommendations",
      name: "recommendations",
      component: Recommendations,
    },
    {
      path: "/recommendations/:country",
      name: "recommendationsList",
      component: RecommendationsList,
    },
    {
      path: "/story",
      name: "story",
      component: Story,
    },
    {
      path: "/download",
      name: "download",
      component: Download,
    },
    {
      path: "/documents",
      name: "documents",
      component: Documents,
    },
    {
      path: "/",
      name: "recommendations",
      component: Recommendations,
    },
  ],
  scrollBehavior(to, from, savedPosition) {
    window.scrollTo(0, 0);
  },
});
