<template>
  <div id="home" class="view home">
    <div v-if="!content || content.length == 0" class="page-loader">
      <Loader></Loader>
    </div>
    <Intro :content="content"></Intro>
    <AboutUsMap :content="content"></AboutUsMap>
    <SurveyResults :content="content"></SurveyResults>
    <BigIssue :content="content"></BigIssue>
    <Barriers :content="content"></Barriers>
    <LookingAhead :content="content"></LookingAhead>
    <TopRecommendations :content="content"></TopRecommendations>
    <ContactUs :content="generalContent"></ContactUs>
    <!--<section class="topics">
      <div class="content">
        <VisualizationCircle></VisualizationCircle>
        <VisualizationForce></VisualizationForce>
      </div>
    </section>-->
  </div>
</template>

<script>
import { getGoogleSheetData } from "@/api";
import Intro from "./sections/Intro.vue";
import AboutUsMap from "./sections/AboutUsMap.vue";
import BigIssue from "./sections/BigIssue.vue";
import Barriers from "./sections/Barriers.vue";
import LookingAhead from "./sections/LookingAhead.vue";
import TopRecommendations from "./sections/TopRecommendations.vue";
import SurveyResults from "./sections/SurveyResults.vue";
import ContactUs from "./sections/ContactUs.vue";
import Loader from "../components/Loader.vue";

export default {
  name: "Story",
  components: {
    Intro,
    AboutUsMap,
    BigIssue,
    Barriers,
    LookingAhead,
    TopRecommendations,
    SurveyResults,
    ContactUs,
    Loader,
  },
  props: ["generalContent"],
  data() {
    return {
      content: [],
    };
  },
  created() {
    window.scrollTo(0, 0);
    getGoogleSheetData(["our_story"]).then((resp) => {
      this.content = resp;
    });
  },
};
</script>

<style lang="stylus">
.home
  .logo
    margin-bottom 30px
  .content
    width: 50%
    @media only screen and (max-width: 768px)
      width 90%
  .block
    font-weight 300
    text-align left
    r-font-size(20,31)
    padding 0px 50px
    margin-top 31px
    @media only screen and (max-width: 768px)
      padding 0px 20px
    a, a:visited
      color: #fff
    &.bold
      font-weight: 700
</style>
